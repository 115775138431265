/* eslint-disable no-underscore-dangle */
import React, { useContext, useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { formatPrice } from '../../utils/format-price';

import Afterpay from '../Afterpay';
import Modal from '../Modal';
import Button from '../Button';
import LineItem from './LineItem';
import FreeSamples from './FreeSamples';
import Price from '../Price';
import FreeShippingProgress from './FreeShippingProgress/FreeShippingProgress';
import Recommendations from '../Recommendations/Recommendations';

import { StoreContext } from '../../context/store-context';
import useCartQuery from './useCartQuery';
import { createCustomAttributesMap, dataLayerPush } from '../../utils';
import { addKlaviyoProfileIdToUrl } from '../../utils/klaviyo';

const ns = `cart-flyout`;

// const gatsbyShopifyStoreUrl = process.env.GATSBY_SHOPIFY_STORE_URL;

const CartFlyout = () => {
	const {
		contentfulGlobalSettings: {
			cartEnableFreeShippingMessage,
			cartFreeShippingThreshold,
			cartEnableFreeSamples,
			cartFreeSamplesThreshold,
			cartFreeSamplesCollection,
			cartEnableFreeGift,
			cartFreeGiftThreshold,
			cartFreeGiftProduct,
			totalAllowedFreeGifts,
			recommendedItemsEnabledCart,
		},
	} = useCartQuery();

	const {
		cart,
		loading,
		isCartFlyoutOpen,
		cartFlyoutClose,
		addVariantsToCart,
		removeLineItem,
		didJustAddToCart,
	} = useContext(StoreContext);

	const [samplesAdded, setSamplesAdded] = useState([]);
	const [freeGiftAddInProgress, setFreeGiftAddInProgress] = useState(false);

	const emptyCart = cart?.lineItems?.length === 0;

	const totalNumItems = cart?.lineItems?.reduce((previous, current) => {
		return previous + (current?.quantity || 0);
	}, 0);

	// -------------------------
	// Helpers
	// -------------------------

	// const hasSubscriptionItem = () => {
	// 	let flag = false;

	// 	cart.lineItems.forEach(item => {
	// 		item.attributes.forEach(({ key }) => {
	// 			if (key === 'selling_plan') {
	// 				flag = true;
	// 			}
	// 		});
	// 	});

	// 	return flag;
	// };

	// -------------------------
	// Free shipping
	// -------------------------

	const meetsFreeShippingRequirements = (() => {
		const total = cart?.cost?.totalAmount?.amount;

		if (!cartEnableFreeShippingMessage || !total) return false;

		return parseFloat(total) >= cartFreeShippingThreshold;
	})();

	// -------------------------
	// Free samples
	// -------------------------

	const meetsFreeSamplesRequirements = (() => {
		const total = cart?.cost?.totalAmount?.amount;

		if (!cartEnableFreeSamples || !total) return false;

		return parseFloat(total) >= cartFreeSamplesThreshold;
	})();

	const maxFreeSamplesInCart = (() => {
		let total = 0;
		const { lineItems } = cart;

		for (let i = 0; i < cart.lineItems.length; i += 1) {
			const item = lineItems[i];
			const customAttributesMap = createCustomAttributesMap(item.attributes);

			if (customAttributesMap?._free_sample) {
				total += 1;
			}

			if (total === totalAllowedFreeGifts) {
				break;
			}
		}

		return total >= totalAllowedFreeGifts;
	})();

	const getFreeSampleLineItems = () => {
		const freeSampleIds = cart.lineItems
			.filter((item) => {
				const ca = createCustomAttributesMap(item.attributes);
				return ca?._free_sample;
			})
			.map((item) => {
				return item.id;
			});

		if (freeSampleIds.length > 0) {
			return freeSampleIds;
		}

		return [];
	};

	const updateSampleProductsState = useCallback(() => {
		const freeSampleVariantIds = cart.lineItems
			.filter((item) => {
				const ca = createCustomAttributesMap(item.attributes);
				return ca?._free_sample;
			})
			.map((item) => {
				return item.variant.id;
			});

		setSamplesAdded(freeSampleVariantIds);
	}, [cart.lineItems]);

	// -------------------------
	// Free Gift
	// -------------------------

	const isFreeGiftInCart = () => {
		const freeGiftLineItem = cart.lineItems
			.filter((lineItem) => {
				return lineItem.variant?.id === atob(cartFreeGiftProduct);
			})
			.map((lineItem) => {
				return lineItem.id;
			});

		return freeGiftLineItem.length > 0;
	};

	const meetsFreeGiftRequirements = useCallback(() => {
		const total = cart?.cost?.totalAmount?.amount;

		if (!total) return false;

		return total > cartFreeGiftThreshold;
	}, [cartFreeGiftThreshold, cart?.cost?.totalAmount?.amount]);

	// -------------------------
	// Free Gift & Free Sample
	// -------------------------

	useEffect(() => {
		let freeLineItemsToRemove = [];

		// Free gift add to cart is in progress
		if (freeGiftAddInProgress) return;

		//	-------------------------
		//	'Free Gift' functionality
		//	-------------------------
		if (cartEnableFreeGift && cartFreeGiftProduct) {
			// Add free gift to cart
			if (meetsFreeGiftRequirements() && !isFreeGiftInCart()) {
				setFreeGiftAddInProgress(true);

				const freeGiftVariantId = atob(cartFreeGiftProduct);
				const customAttributes = [
					{
						key: '_free_gift',
						value: 'true',
					},
				];

				addVariantsToCart([
					{
						variantId: freeGiftVariantId,
						quantity: 1,
						attributes: customAttributes,
					},
				]).then(() => {
					setFreeGiftAddInProgress(false);
				});
			}

			// Add Free Gift Line items to the remove items array
			if (!meetsFreeGiftRequirements() && isFreeGiftInCart()) {
				const freeGiftLineItem = cart.lineItems
					.filter((lineItem) => {
						return lineItem.variant.id === atob(cartFreeGiftProduct);
					})
					.map((lineItem) => {
						return lineItem.id;
					});

				if (freeGiftLineItem.length > 0) {
					freeLineItemsToRemove = [...freeGiftLineItem];
				}
			}
		}

		//	---------------------------
		//	'Free Sample' functionality
		//	---------------------------
		if (cartEnableFreeSamples) {
			// Add Free Sample Line items to the remove items array
			if (!meetsFreeSamplesRequirements) {
				const freeSampleLineItems = getFreeSampleLineItems();
				freeLineItemsToRemove = [
					...freeLineItemsToRemove,
					...freeSampleLineItems,
				];
			}
		}

		//	--------------------
		//	Shared functionality
		//	--------------------
		//	Removes both sample and free gift line items in the same request
		if (freeLineItemsToRemove.length > 0) {
			removeLineItem(cart.id, freeLineItemsToRemove).then(() => {
				setSamplesAdded([]);
			});
		}

		updateSampleProductsState();
	}, [cart.lineItems]); // eslint-disable-line

	// -------------------------
	// Total savings
	// -------------------------

	const totalSavings = (() => {
		const total = cart?.cost?.totalAmount?.amount;
		let output = 0;

		if (!total) return output;

		cart.lineItems.forEach((item) => {
			const customAttributesMap = createCustomAttributesMap(item.attributes);
			const sellingPlanJson = customAttributesMap?._selling_plan_json;
			const comparePrice = Number(item?.variant?.compareAtPrice?.amount);
			const price = Number(item?.variant?.price?.amount);
			const qty = item?.quantity;
			let diff;

			if (sellingPlanJson) {
				let sellingPlan;

				try {
					sellingPlan = JSON.parse(sellingPlanJson);
				} catch (error) {
					// eslint-disable-next-line
					console.error(error);
				}

				const discount = parseInt(sellingPlan.discount_amount, 10);
				const autoRenewPrice = price - price * (discount / 100);

				diff = (price - autoRenewPrice) * qty;
				output += diff;
			} else if (comparePrice > price) {
				diff = (comparePrice - price) * qty;
				output += diff;
			}
		});

		return output;
	})();

	// -------------------------
	// Estimated/Grand Total
	// -------------------------

	const estimatedTotal = (() => {
		const total = cart?.cost?.totalAmount.amount;
		return total;
		// if (!hasSubscriptionItem() && total) {
		// 	return total;
		// }

		// let output = 0;
		// if (!total) return output;

		// cart.lineItems.forEach(item => {
		// 	const customAttributesMap = createCustomAttributesMap(item.customAttributes);
		// 	const sellingPlanJson = customAttributesMap?._selling_plan_json;
		// 	const price = Number(item?.variant?.priceV2?.amount);
		// 	const qty = item?.quantity;
		// 	let itemTotal;

		// 	if (sellingPlanJson) {
		// 		let sellingPlan;

		// 		try {
		// 			sellingPlan = JSON.parse(sellingPlanJson);
		// 		} catch (error) {
		// 			// eslint-disable-next-line
		// 			console.error(error);
		// 		}

		// 		const discount = parseInt(sellingPlan.discount_amount, 10);
		// 		const autoRenewPrice = price - (price * (discount / 100));

		// 		itemTotal = autoRenewPrice * qty;
		// 		output += itemTotal;
		// 	} else {
		// 		itemTotal = price * qty;
		// 		output += itemTotal;
		// 	}
		// });

		// return output;
	})();

	// -------------------------
	// Subscription
	// -------------------------

	// Build url with line item parameters that will be used on the Shopify
	// '/cart' page to obtain a cart_token & redirect to checkout
	// NOTE - changes here directly affect script on Shopify cart page
	// DEPRECATED: We're using Shopify selling plans to handle subscription products
	// const buildShopifyCartUrl = items => {
	// 	let lineItems = '';

	// 	items.forEach(({
	// 		quantity,
	// 		variant: { id },
	// 		customAttributes,
	// 	}, i) => {
	// 		const variantId = id.split('/').pop();
	// 		const sellingPlan = customAttributes.filter(attribute => {
	// 			return attribute.key === 'selling_plan';
	// 		});
	// 		const properties = customAttributes
	// 			.filter(attribtue => {
	// 				// selling plan is not added to line item
	// 				// properties so we exclude them here.
	// 				return attribtue.key !== 'selling_plan'
	// 				&& attribtue.key !== '_selling_plan_json';
	// 			})
	// 			.map(attribtue => {
	// 				return `${ attribtue.key }:::${ attribtue.value }`;
	// 			})
	// 			.join(',');

	// 		// Required add to cart arguments
	// 		const variantIdParam = `id===${ variantId }`;
	// 		const quantityParam = `&&&quantity===${ quantity }`;

	// 		// Optional add to cart arguments
	// 		const sellingPlanParam = sellingPlan[0]?.value
	// 			? `&&&selling_plan===${ sellingPlan[0]?.value }`
	// 			: '';
	// 		const propertiesParam = properties?.length > 0
	// 			? `&&&properties===${ properties }`
	// 			: '';

	// 		lineItems += `${ variantIdParam }${ quantityParam }${ sellingPlanParam }${ propertiesParam }`;

	// 		if (i !== items.length - 1) {
	// 			lineItems += '###';
	// 		}
	// 	});

	// 	let params = `&line_items=${ btoa(lineItems) }`;

	// 	if (cart?.id) {
	// 		params += `&cartid=${ btoa(cart.id) }`;
	// 	}

	// 	// Need to be running shopify theme server for this to work.
	// 	if (window.location.hostname.includes('localhost')) {
	// 		return `http://127.0.0.1:9292/cart/?${ params }`;
	// 	}

	// 	return `https://${ gatsbyShopifyStoreUrl }/cart/?${ params }`;
	// };

	// If no subscription items present, send user to Shopify
	// checkout, otherwise send to Shopify /cart page
	const handleCheckout = () => {
		const checkoutUrlWithKlaviyoId = addKlaviyoProfileIdToUrl(cart.checkoutUrl);
		window.open(checkoutUrlWithKlaviyoId, '_self');
		// if (hasSubscriptionItem()) {
		// 	const shopifyCartUrl = buildShopifyCartUrl(cart.lineItems);
		// 	window.open(shopifyCartUrl, '_self');
		// } else {
		// 	window.open(cart.webUrl, '_self');
		// }
		dataLayerPush().beginCheckout({
			items: cart.lineItems.map((item) => {
				return {
					quantity: item.quantity,
					product: item.variant.product,
					variant: {
						...item.variant,
						price: item.variant?.price?.amount,
					},
				};
			}),
		});
	};

	const rootClassnames = classNames({
		[`${ns}`]: true,
	});

	const [productIds, variantIds] = cart.lineItems.reduce(
		(acc, cur) => {
			const { variant } = cur;
			const variantId = variant.id;
			const productId = variant.product.id;

			if (acc[0].indexOf(productId) === -1) {
				acc[0].push(productId);
			}

			if (acc[1].indexOf(variantId) === -1) {
				acc[1].push(variantId);
			}

			return acc;
		},
		[[], []]
	);

	return (
		<Modal
			variant={`drawer`}
			onRequestClose={() => {
				cartFlyoutClose();
			}}
			isOpen={isCartFlyoutOpen}
		>
			<div className={rootClassnames}>
				<div className={`${ns}__header`}>
					<div className={`${ns}__container`}>
						<div className={`${ns}__header-title`}>
							<h6 className={`${ns}__header-title`}>Your Bag</h6>
							<span>{`(${totalNumItems})`}</span>
						</div>
					</div>
				</div>
				<div className={`${ns}__body`}>
					{emptyCart ? (
						<div className={`${ns}__empty`}>
							<div className={`${ns}__container`}>
								<h5 className={`${ns}__empty-title`}>Your cart is empty</h5>
								<div>
									<Button
										to={`/collections/all-lashes`}
										onClick={() => {
											cartFlyoutClose();
										}}
										variant={`text-black`}
										withArrow
									>
										Continue Shopping
									</Button>
								</div>
							</div>
						</div>
					) : (
						<>
							<FreeShippingProgress
								cartSubtotalAmount={cart?.cost?.totalAmount?.amount}
								goalAmount={cartFreeShippingThreshold}
							/>
							<div className={`${ns}__items`}>
								<div className={`${ns}__container`}>
									<ul className={`${ns}__line-items`}>
										{cart.lineItems.map((item) => {
											return <LineItem item={item} key={item.id} />;
										})}
									</ul>
								</div>
							</div>
							{meetsFreeSamplesRequirements &&
								cartFreeSamplesCollection &&
								!maxFreeSamplesInCart && (
									<div className={`${ns}__free-samples`}>
										<div className={`${ns}__container`}>
											<FreeSamples
												collectionId={cartFreeSamplesCollection}
												samplesAdded={samplesAdded}
												totalAllowedFreeGifts={totalAllowedFreeGifts}
												requiredMinimumSubtotal={cartFreeSamplesThreshold}
												setSamplesAdded={setSamplesAdded}
											/>
										</div>
									</div>
								)}

							{recommendedItemsEnabledCart && (
								<Recommendations
									className={`${ns}__container`}
									productIds={productIds}
									variantIds={variantIds}
									isInCart
									limit={3}
									title={`Recommended For You`}
								/>
							)}

							<div className={`${ns}__body-totals ${ns}__container`}>
								<div className={`${ns}__subtotal`}>
									<p>Subtotal</p>
									<p>
										{formatPrice(
											cart.cost.subtotalAmount.currencyCode,
											cart.cost.subtotalAmount.amount
										)}
									</p>
								</div>
								{totalSavings > 0 && (
									<div className={`${ns}__savings`}>
										<p>Savings</p>
										<div>
											-
											<Price
												price={totalSavings}
												// eslint-disable-next-line
												currencyCode={cart.cost.subtotalAmount.currencyCode}
												decimalPlaces={2}
											/>
										</div>
									</div>
								)}
								<div className={`${ns}__tax`}>
									<p>Tax (calculated at check-out)</p>
									<p>--</p>
								</div>
								<div className={`${ns}__shipping`}>
									<p>Shipping</p>
									{meetsFreeShippingRequirements ? <p>Free</p> : <p>--</p>}
								</div>
								<Afterpay
									price={estimatedTotal}
									currencyCode={cart.cost.subtotalAmount.currencyCode}
								/>
							</div>
						</>
					)}
				</div>
				{!emptyCart && (
					<div className={`${ns}__footer`}>
						<div className={`${ns}__container`}>
							<div className={`${ns}__footer-total`}>
								<p>Estimated Total</p>
								<p>
									{formatPrice(
										cart.cost.totalAmount.currencyCode,
										estimatedTotal
									)}
								</p>
							</div>
							<div className={`${ns}__checkout`}>
								<Button
									onClick={handleCheckout}
									disabled={didJustAddToCart || loading}
								>
									{didJustAddToCart || loading
										? 'Updating…'
										: 'Proceed to Checkout'}
								</Button>
							</div>
							<div className={`${ns}__shipping-promo-note`}>
								<p className={`text-small`}>
									Shipping and promotions calculated in checkout.
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default CartFlyout;
