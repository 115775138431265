import React, { useEffect } from 'react';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';
import CartFlyout from '../../components/CartFlyout';
import Footer from '../Footer';
import Header from '../Header';

import { parseJson } from '../../utils';

const ns = `layout-default`;

const DefaultLayout = ({ children, location }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	useEffect(() => {
		window.OptanonWrapper = () => { };
	}, []);

	const isOneTrustAutoblockEnabled = (
		process.env.GATSBY_ONE_TRUST_COOKIES_ID && parseJson(process.env.GATSBY_ONE_TRUST_AUTOBLOCK_ENABLED)
	);

	return (
		<>
			<Helmet>
				{isOneTrustAutoblockEnabled &&
					<script
						type={'text/javascript'}
						src={`https://cdn.cookielaw.org/consent/${process.env.GATSBY_ONE_TRUST_COOKIES_ID}/OtAutoBlock.js`}
					/>
				}
				{process.env.GATSBY_ONE_TRUST_COOKIES_ID &&
					<script
						type={'text/javascript'}
						src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
						data-domain-script={process.env.GATSBY_ONE_TRUST_COOKIES_ID}
					/>
				}
			</Helmet>
			<div className={rootClassnames}>
				<Header pathname={location.pathname} />
				<main>
					{children}
				</main>
				<Footer />
			</div>
			<CartFlyout />
			<div className={'select'} id={'select-portal'} />
		</>
	);
};

export default DefaultLayout;
