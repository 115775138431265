/* eslint-disable import/extensions, import/no-unresolved, no-underscore-dangle */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';

import { StoreContext } from '../../../context/store-context';

import 'swiper/css';
import 'swiper/css/free-mode';

import Button from '../../Button';

import { createCustomAttributesMap, getCollectionById } from '../../../utils';

const ns = `cart-free-samples`;

const FreeSampleCard = ({
	title,
	images,
	variants,
	samplesAdded,
	totalAllowedFreeGifts,
	requiredMinimumSubtotal
}) => {

	const {
		cart,
		addVariantsToCart,
		removeLineItem,
		loading,
		didJustAddToCart,
	} = React.useContext(StoreContext);

	const isSampleAdded = samplesAdded.includes(variants?.nodes[0].id);

	const defaultImageHeight = 160;
	const defaultImageWidth = 120;
	let imageData;
	if (images?.nodes) {
		const image = images.nodes[0];
		try {
			imageData = getShopifyImage({
				image,
				layout: 'constrained',
				width: defaultImageWidth,
				height: defaultImageHeight,
			});
		} catch (e) {
			// eslint-disable-next-line
			console.error(e);
		}
	}

	const handleAddToCart = e => {
		e.preventDefault();

		const customAttributes = [
			{
				key: '_free_sample',
				value: String(true),
			},
			{
				key: '_config_free_sample_threshold_number_allowed',
				value: String(totalAllowedFreeGifts),
			},
			{
				key: '_config_free_sample_threshold_subtotal',
				value: String(requiredMinimumSubtotal),
			}
		];

		const currentSamplesInCart = cart.lineItems.filter(item => {
			const currentItemCustomAttributesMap = createCustomAttributesMap(item.attributes);
			return currentItemCustomAttributesMap?._free_sample && item;
		});

		const variantId = variants.nodes[0].id;
		const newLineItem = {
			variantId,
			quantity: 1,
			attributes: customAttributes,
		};

		if (currentSamplesInCart.length >= totalAllowedFreeGifts) {
			const lineItemToRemove = currentSamplesInCart[0];

			removeLineItem(cart.id, [lineItemToRemove.id])
				.then(() => {
					addVariantsToCart([newLineItem])
				});
		} else {
			addVariantsToCart([newLineItem])
		}
	};

	return (
		<>
			<div className={`${ ns }__slide-media`}>
				<GatsbyImage
					alt={title}
					image={imageData}
					loading={'lazy'}
				/>
			</div>
			<div className={`${ ns }__slide-copy`}>
				<p className={`text-small`} title={title}>
					{title}
				</p>
				<div>
					<Button
						onClick={handleAddToCart}
						disabled={didJustAddToCart || loading || isSampleAdded}
						variant={`outline-black`}
					>
						{isSampleAdded ? 'Added' : 'Add'}
					</Button>
				</div>
			</div>
		</>
	);
};

const FreeSamples = ({
	collectionId,
	samplesAdded,
	totalAllowedFreeGifts,
	requiredMinimumSubtotal
}) => {
	const [items, setItems] = useState([]);

	useEffect(() => {
		let isMounted = true;

		if (collectionId) {
			getCollectionById(collectionId)
				.then(resolvedProducts => {
					if (isMounted) {
						setItems(resolvedProducts);
					}
				})
				.catch(err => {
					// eslint-disable-next-line
					console.log(err.message);
				});
		}

		return () => {
			isMounted = false;
		};
	}, [collectionId]);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	if (items.length === 0) return null;

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__heading`}>
				<p className={`text-small`}>Choose A Free Gift!</p>
			</div>
			<Swiper
				slidesPerView={'auto'}
				spaceBetween={30}
				freeMode
				modules={[FreeMode]}
				className={`${ ns }__slider`}
			>
				{items.map(item => {
					return (
						<SwiperSlide key={item.id} className={`${ ns }__slide`}>
							<FreeSampleCard
								{...item}
								samplesAdded={samplesAdded}
								totalAllowedFreeGifts={totalAllowedFreeGifts}
								requiredMinimumSubtotal={requiredMinimumSubtotal}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default FreeSamples;
