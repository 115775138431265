import { useStaticQuery, graphql } from 'gatsby';

const useCartQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			contentfulGlobalSettings {
				cartFreeShippingThreshold
				cartEnableFreeShippingMessage
				cartEnableFreeSamples
				cartFreeSamplesThreshold
				cartFreeSamplesCollection
				cartEnableFreeGift
				cartFreeGiftThreshold
				cartFreeGiftProduct
				totalAllowedFreeGifts
				recommendedItemsEnabledCart
			}
		}
	`);

	return data;
};

export default useCartQuery;
